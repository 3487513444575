"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.columns = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const material_1 = require("@mui/material");
const walletDetails_1 = require("../../../wallet/component/walletDetails");
exports.columns = [
    {
        id: 'date',
        ui() {
            return ((0, jsx_runtime_1.jsx)(material_1.Grid, { container: true, flex: 1, children: (0, jsx_runtime_1.jsx)(walletDetails_1.TypographyFieldColumn, { children: "Date" }) }));
        },
        width: '15%',
    },
    {
        id: 'amount',
        ui() {
            return (0, jsx_runtime_1.jsx)(walletDetails_1.TypographyFieldColumn, { sx: { textAlign: 'right' }, children: "Amount" });
        },
        width: '15%',
    },
    {
        id: 'details',
        ui() {
            return (0, jsx_runtime_1.jsx)(walletDetails_1.TypographyFieldColumn, { children: "Details" });
        },
        width: '35%',
    },
    {
        id: 'user',
        ui() {
            return (0, jsx_runtime_1.jsx)(walletDetails_1.TypographyFieldColumn, { children: "User" });
        },
        width: '35%',
    },
];
