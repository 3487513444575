"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const material_1 = require("@mui/material");
const react_1 = require("react");
const zustandStore_1 = require("../../../../zustandStore");
const AuthForm = (0, react_1.lazy)(() => Promise.resolve().then(() => __importStar(require('../loginform/authForm'))));
const Steps = (0, react_1.lazy)(() => Promise.resolve().then(() => __importStar(require('./steps'))));
const RegisterForm = (0, react_1.lazy)(() => Promise.resolve().then(() => __importStar(require('./steps/defaultRegistration/step_1/registerForm'))));
const TemporaryPasswordForm = (0, react_1.lazy)(() => Promise.resolve().then(() => __importStar(require('./steps/defaultRegistration/step_2/temporaryPasswordForm'))));
const PasswordSetupForm = (0, react_1.lazy)(() => Promise.resolve().then(() => __importStar(require('./steps/defaultRegistration/step_3/passwordSetupForm'))));
const SetupTwoFactorAuth = (0, react_1.lazy)(() => Promise.resolve().then(() => __importStar(require('./steps/defaultRegistration/step_4/setupTwoFactorAuth'))));
const VerifyPhoneSetupForm = (0, react_1.lazy)(() => Promise.resolve().then(() => __importStar(require('./steps/defaultRegistration/step_5/verifyPhoneSetupForm'))));
const EnterValidationCode = (0, react_1.lazy)(() => Promise.resolve().then(() => __importStar(require('./steps/defaultRegistration/step_6/enterValidationCode'))));
const AllSetMessage = (0, react_1.lazy)(() => Promise.resolve().then(() => __importStar(require('./steps/defaultRegistration/step_7/allSetMessage'))));
const InviteRegisterForm = (0, react_1.lazy)(() => Promise.resolve().then(() => __importStar(require('./steps/invitationRegistration/step_1/inviteRegisterForm'))));
// Determine if it's an invitation or registration
const isInvitation = !location.pathname.includes('register') && location.pathname.includes('signin/activate');
const isRegisterActivationByEmail = location.pathname.includes('signin/register/activate');
// register/activate
const InvitationComponents = (data) => [
    {
        component: (0, jsx_runtime_1.jsx)(InviteRegisterForm, {}, "InviteRegisterForm"),
    },
    {
        component: (0, jsx_runtime_1.jsx)(PasswordSetupForm, {}, "PasswordSetupForm"),
    },
    ...(data.phoneSetup?.status === 'verification_needed'
        ? [
            {
                component: (0, jsx_runtime_1.jsx)(VerifyPhoneSetupForm, {}, "VerifyPhoneSetupForm"),
            },
        ]
        : []),
    ...(data.mfaSms?.status === 'login_required' || data.phoneSetup?.status === 'verification_needed'
        ? [
            {
                component: ((0, jsx_runtime_1.jsx)(EnterValidationCode, { challengeName: data.mfaSms?.status === 'login_required' ? 'SMS_MFA' : 'SOFTWARE_TOKEN_MFA' }, "EnterValidationCode")),
            },
        ]
        : []),
    {
        component: (0, jsx_runtime_1.jsx)(AllSetMessage, {}, "AllSetMessage"),
    },
];
const RegisterComponents = (data) => {
    const components = [
        ...(window.config.IS_PRODUCTION
            ? []
            : [
                {
                    // DO NOT SHOW ON PROD
                    component: (0, jsx_runtime_1.jsx)(RegisterForm, {}, "RegisterForm"),
                },
            ]),
        {
            component: (0, jsx_runtime_1.jsx)(TemporaryPasswordForm, {}, "TemporaryPasswordForm"),
        },
        {
            component: (0, jsx_runtime_1.jsx)(PasswordSetupForm, {}, "passwordSetupForm"),
        },
        ...(data.mfaOtp?.status === 'setup_required'
            ? [
                {
                    component: (0, jsx_runtime_1.jsx)(SetupTwoFactorAuth, {}, "setupTwoFactorAuth"),
                },
            ]
            : []),
        ...(data.phoneSetup?.status === 'verification_needed'
            ? [
                {
                    component: (0, jsx_runtime_1.jsx)(VerifyPhoneSetupForm, {}, "VerifyPhoneSetupForm"),
                },
            ]
            : []),
        ...(data.mfaSms?.status === 'login_required' || data.phoneSetup?.status === 'verification_needed'
            ? [
                {
                    component: ((0, jsx_runtime_1.jsx)(EnterValidationCode, { challengeName: data.mfaSms?.status === 'login_required' ? 'SMS_MFA' : 'SOFTWARE_TOKEN_MFA' }, "EnterValidationCode")),
                },
            ]
            : []),
        {
            component: (0, jsx_runtime_1.jsx)(AllSetMessage, {}, "AllSetMessage"),
        },
    ];
    if (isRegisterActivationByEmail) {
        // if registering by email, remove the first component (RegisterForm)
        return components.slice(1);
    }
    return components;
};
const stepComponents = (data) => {
    const componentsCollection = isInvitation ? InvitationComponents(data) : RegisterComponents(data);
    return componentsCollection;
};
const AccountPage = () => {
    const { steps, setStepsCollection } = (0, zustandStore_1.useZustand)(state => ({
        ...state,
    }));
    (0, react_1.useEffect)(() => {
        const newStepsArray = stepComponents(steps.data).map((_, index) => ({
            index,
            isCompleted: index <= steps.currentStep,
        }));
        if (steps.collection.length !== newStepsArray.length) {
            setStepsCollection(newStepsArray);
        }
    }, [steps.collection, steps.currentStep, steps.data]);
    return ((0, jsx_runtime_1.jsx)(AuthForm, { children: (0, jsx_runtime_1.jsx)(material_1.Box, { sx: {
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'center',
                flexBasis: '390px',
                maxWidth: '390px',
                margin: '0 auto',
                paddingTop: '45px',
            }, children: (0, jsx_runtime_1.jsxs)(react_1.Suspense, { fallback: (0, jsx_runtime_1.jsx)("div", {}), children: [(0, jsx_runtime_1.jsx)(Steps, {}), stepComponents(steps.data)[steps.currentStep]?.component] }) }) }));
};
exports.default = AccountPage;
