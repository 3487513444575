"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useTeamColumns = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const material_1 = require("@mui/material");
const strings_1 = require("../../../../../constants/strings");
const useTeamColumns = (currency) => {
    return [
        {
            id: 'team',
            ui() {
                return ((0, jsx_runtime_1.jsx)(material_1.Grid, { container: true, children: (0, jsx_runtime_1.jsx)(material_1.Typography, { variant: "h5", "ts-variant": 'tableHeaderTitle', children: strings_1.dashboardStrings.TEAM_FIELD }) }));
            },
        },
        {
            id: 'teamNumberOfMembers',
            ui() {
                return ((0, jsx_runtime_1.jsx)(material_1.Grid, { container: true, children: (0, jsx_runtime_1.jsx)(material_1.Typography, { variant: "h5", "ts-variant": 'tableHeaderTitle', children: strings_1.dashboardStrings.MEMBER_FIELD }) }));
            },
        },
        {
            id: 'teamCards',
            ui() {
                return ((0, jsx_runtime_1.jsx)(material_1.Grid, { container: true, children: (0, jsx_runtime_1.jsx)(material_1.Typography, { variant: "h5", "ts-variant": 'tableHeaderTitle', children: strings_1.dashboardStrings.ACTIVE_CARDS_FIELD }) }));
            },
        },
        {
            id: 'approved',
            ui() {
                return ((0, jsx_runtime_1.jsx)(material_1.Grid, { container: true, children: (0, jsx_runtime_1.jsx)(material_1.Typography, { variant: "h5", "ts-variant": 'tableHeaderTitle', children: `${strings_1.dashboardStrings.APPROVED_FIELD} ${currency}` }) }));
            },
        },
        {
            id: 'spent',
            ui() {
                return ((0, jsx_runtime_1.jsx)(material_1.Grid, { container: true, children: (0, jsx_runtime_1.jsx)(material_1.Typography, { variant: "h5", "ts-variant": 'tableHeaderTitle', children: `${strings_1.dashboardStrings.SPENT_FIELD} ${currency}` }) }));
            },
        },
        {
            id: 'available',
            ui() {
                return ((0, jsx_runtime_1.jsx)(material_1.Grid, { container: true, children: (0, jsx_runtime_1.jsx)(material_1.Typography, { variant: "h5", "ts-variant": 'tableHeaderTitle', children: `${strings_1.dashboardStrings.AVALIABLE_FIELD} ${currency}` }) }));
            },
        },
        {
            id: 'teamActions',
            ui() {
                return ((0, jsx_runtime_1.jsx)(material_1.Grid, { container: true, direction: "row", flex: 0.5, justifyContent: "flex-end", alignItems: "flex-end", children: (0, jsx_runtime_1.jsx)(material_1.Typography, { variant: "h5", "ts-variant": 'tableHeaderTitle', children: '' }) }));
            },
        },
    ];
};
exports.useTeamColumns = useTeamColumns;
