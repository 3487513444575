const MainController = require('../main/MainController');

/* @ngInject */
function routing($locationProvider, $stateProvider, $uiRouterProvider, $windowProvider) {
	const $window = $windowProvider.$get();
	// remove ! hash prefix
	// https://github.com/angular-ui/ui-router/issues/3203#issuecomment-267782147
	$locationProvider.hashPrefix('');
	$locationProvider.html5Mode({
		enabled: true,
		requireBase: true,
		rewriteLinks: true,
	});

	$stateProvider
		.state('landingPage', {
			url: '/',
		})
		.state('/react', {
			url: 'react',
		})
		.state('/tos', {
			url: '/tos',
		})
		.state('signin', {
			abstract: true,
			url: '/signin',
		})
		.state('signin.forgotPassword', {
			url: '/password/forgot',
		})
		.state('signin.login', {
			url: '/login',
		})
		.state('signin.message', {
			url: '/message',
		})
		.state('signin.activate', {
			onEnter: [
				'$state',
				'$stateParams',
				'$window',
				function ($state, $stateParams, $window) {
					const userId = $stateParams.userId;
					const activationToken = $stateParams.activationToken;
					const reactRoute = `/activate/${userId}/${activationToken}`;
					$window.location.href = reactRoute;
				},
			],
		})
		.state('signin.register', {
			onEnter: [
				'$state',
				'$stateParams',
				'$window',
				function ($state, $stateParams, $window) {
					const userId = $stateParams.userId;
					const activationToken = $stateParams.activationToken;
					const reactRoute = `/register/${userId}/${activationToken}`;
					$window.location.href = reactRoute;
				},
			],
		})
		.state('signin.registerActivate', {
			onEnter: [
				'$state',
				'$stateParams',
				'$window',
				function ($state, $stateParams, $window) {
					const userId = $stateParams.userId;
					const activationToken = $stateParams.activationToken;
					const reactRoute = `/register/activate/${userId}/${activationToken}`;
					$window.location.href = reactRoute;
				},
			],
		})
		.state('signin.requestDemo', {
			url: '/request-demo',
		})
		.state('signin.resetPassword', {
			url: 'signin/reactivate/:userId/:activationToken',
			onEnter: [
				'$state',
				'$stateParams',
				'$window',
				function ($state, $stateParams, $window) {
					const userId = $stateParams.userId;
					const activationToken = $stateParams.activationToken;
					const reactRoute = `/signin/reactivate/${userId}/${activationToken}`;
					$window.location.href = reactRoute;
				},
			],
		})
		.state('main', {
			abstract: true,
			controller: 'MainController',
			resolve: MainController.resolve,
			template: require('../main/main.html'),
			url: '/',
		})
		.state('main.approvals', {
			url: 'approvals',
		})
		.state('main.approvals.pending', {
			url: '',
		})
		.state('main.approvals.approved', {
			url: '/approved',
		})
		.state('main.approvals.declined', {
			url: '/declined',
		})
		.state('main.approvals.canceled', {
			url: '/canceled',
		})
		.state('main.approval', {
			url: 'approvals/:approvalId',
		})
		.state('main.activity', {
			url: 'activity',
		})
		.state('main.activity.pending', {
			url: '',
		})
		.state('main.activity.approved', {
			url: '/approved',
		})
		.state('main.activity.declined', {
			url: '/declined',
		})
		.state('main.activity.canceled', {
			url: '/canceled',
		})
		.state('main.companyDashboard', {
			url: 'dashboard',
		})
		.state('main.approverDashboard', {
			url: 'dashboard/team/:teamId',
		})
		.state('main.manageSettlements', {
			url: 'manage-settlements',
		})
		.state('main.team-manageSettlements', {
			url: 'teams/:teamId/manage-settlements',
		})
		.state('main.requests', {
			url: 'requests/:requestId',
		})
		.state('main.wallet', {
			url: 'wallet',
		})
		.state('main.wallet.myCards', {
			url: '/my-cards',
		})
		.state('main.wallet.team', {
			url: '/team',
		})
		.state('main.walletTeamMemberView', {
			url: 'wallet/team/:teamId/user/:userId',
		})
		.state('main.walletMemberView', {
			url: 'wallet/user/:userId',
		})
		.state('main.walletDetailRouter', {
			// TODO: do we use this ?
			url: 'wallet/card/:virtualCardId',
		})
		.state('main.walletDetail', {
			url: 'wallet/:taskId',
		})
		.state('main.settings', {
			abstract: true,
			url: 'settings',
		})
		.state('main.settings.overview', {
			url: '/overview',
		})
		.state('main.settings.people', {
			url: '/people',
		})
		.state('main.settings.teams', {
			url: '/teams',
		})
		.state('main.settings.cards', {
			url: '/cards',
		})
		.state('main.settings.coding', {
			url: '/coding',
		})
		.state('main.teamSettings', {
			abstract: true,
			url: 'team',
		})
		.state('main.teamSettings.overview', {
			url: '/overview',
		})
		.state('main.teamSettings.sourceCards', {
			url: '/cards',
		})
		.state('main.teamSettings.people', {
			url: '/people',
		})
		.state('main.teamSettings.coding', {
			url: '/coding',
		});

	const isOldV2Route = path => path.substring(0, 3) === '/v2';
	const redirectWithoutV2InPath = path => {
		const { protocol, host } = $window.location;
		$window.location.href = `${protocol}//${host}${path.substring(3)}`;
	};

	$uiRouterProvider.urlService.rules.otherwise((matchValue, urlParts, router) => {
		const { path } = urlParts;

		if (isOldV2Route(path)) {
			redirectWithoutV2InPath(path);
			return;
		}

		if (window.location.pathname.includes('get-started')) {
			router.stateService.go('signin.login');
		} else if (window.location.pathname.includes('goV2IntroWizard/signed-in')) {
			router.stateService.go('main.wallet', {}, { location: 'replace' });
		} else {
			const routesToIgnore = ['signin/activate', 'signin/reactivate'];
			const error = { statusCode: 404 };
			if (routesToIgnore.some(path => window.location.pathname.includes(path))) {
				return true;
			}

			router.stateService.go('main.error', { error }, { location: 'replace' });
		}
	});

	$uiRouterProvider.urlService.rules.when(/^\/react\/.*$/, function () {
		// Prevent AngularJS from handling routes starting with /react so it does not go to /error path
		return true;
	});
}

module.exports = routing;
