"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.muiTypographyOverrides = void 0;
const tsGoThemeVars_1 = require("../tsGoThemeVars");
function getH6Style(theme) {
    return {
        margin: '0',
        fontSize: theme.typography.pxToRem(theme.typography.tsFontSize.big),
        display: 'block',
        position: 'sticky',
        backgroundColor: 'white',
        inset: '0',
        padding: '14px 20px 5px',
        zIndex: '10',
        fontWeight: theme.typography.tsFontWeight.default,
        '&::after': {
            content: '""',
            height: '1px',
            position: 'absolute',
            left: '0',
            right: '0',
            bottom: '0',
            pointerEvents: 'none',
            backgroundColor: '#cad7dc',
        },
    };
}
function getSubtitle1Style(theme) {
    return {
        fontSize: theme.typography.pxToRem(theme.typography.tsFontSize.default),
        fontWeight: theme.typography.tsFontWeight.default,
        marginBottom: '0.5rem',
        marginTop: '20px',
        paddingLeft: '0',
    };
}
function getSubtitle2Style(theme) {
    return {
        fontSize: theme.typography.pxToRem(theme.typography.tsFontSize.mini),
        fontWeight: theme.typography.tsFontWeight.bold,
    };
}
function getBody1Style(theme) {
    return {
        fontSize: theme.typography.pxToRem(theme.typography.tsFontSize.default),
        fontWeight: theme.typography.tsFontWeight.default,
        ...(window.cardProvider.IS_HSBC_PROVIDER_SETUP
            ? {
                fontSize: '12px',
                fontWeight: 400,
                color: '#000000',
            }
            : {}),
    };
}
function getActiveButtonStyle(theme) {
    return {
        fontSize: theme.typography.pxToRem(theme.typography.tsFontSize.default),
        color: window.cardProvider.IS_HSBC_PROVIDER_SETUP ? theme.palette.hsbcColor.green : theme.palette.tsGoColor.green,
    };
}
function getInactiveButtonStyle(theme) {
    return {
        fontSize: theme.typography.pxToRem(theme.typography.tsFontSize.default),
        color: theme.palette.tsGoColor.orange,
    };
}
function getBody2Style(theme) {
    return {
        fontSize: theme.typography.pxToRem(theme.typography.tsFontSize.small),
    };
}
function getTableCellTitleStyle(theme) {
    return {
        fontSize: theme.typography.pxToRem(theme.typography.tsFontSize.default),
        fontWeight: theme.typography.fontWeightRegular,
        span: {
            color: 'hsl(198, 20%, 59%)',
            fontSize: theme.typography.pxToRem(theme.typography.tsFontSize.small),
            fontStyle: 'normal',
        },
        width: '80px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    };
}
function getTableCellTitleNameStyle(theme) {
    return {
        fontSize: theme.typography.pxToRem(theme.typography.tsFontSize.default),
        fontWeight: theme.typography.fontWeightRegular,
        width: '300px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    };
}
function getTableCellSubtitleStyle(theme) {
    return {
        fontSize: theme.typography.pxToRem(theme.typography.tsFontSize.small),
        fontWeight: theme.typography.fontWeightRegular,
        color: theme.palette.tsGoColor.grayDark,
        margin: 0,
    };
}
function getTableCellSubtitleStyleSC(theme) {
    return {
        fontSize: theme.typography.pxToRem(theme.typography.tsFontSize.small),
        fontWeight: theme.typography.fontWeightRegular,
        color: theme.palette.tsGoColor.grayDark,
        margin: 0,
        width: '80px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    };
}
function getTableHeaderCellTitleStyle(theme) {
    // this is used in Table to the title of the column header cell
    return {
        fontSize: theme.typography.pxToRem(theme.typography.tsFontSize.mini),
        fontWeight: theme.typography.fontWeightMedium,
        textTransform: 'uppercase',
        color: theme.palette.tsGoColor.black,
        alignSelf: 'center',
        ...(window.cardProvider.IS_HSBC_PROVIDER_SETUP
            ? {
                fontSize: '12px',
                fontWeight: 500,
                color: '#000000',
                textTransform: 'none',
            }
            : {}),
    };
}
function getNoListStyle() {
    return {
        color: 'hsl(198, 20%, 49%)',
        textAlign: 'center',
    };
}
function getImgSpanStyle(theme) {
    return {
        paddingLeft: theme.tsGoUnit.tsUnitDouble,
        width: '100%',
        display: 'block',
        fontWeight: theme.typography.tsFontWeight.bold,
        paddingRight: theme.tsGoUnit.tsUnit,
    };
}
function getImgSideSpanStyle(theme) {
    return {
        width: '100%',
        display: 'block',
        paddingRight: theme.tsGoUnit.tsUnit,
        fontSize: window.cardProvider.IS_HSBC_PROVIDER_SETUP
            ? theme.typography.tsFontSize.mini
            : theme.typography.pxToRem(theme.typography.tsFontSize.default),
        fontWeight: theme.typography.tsFontWeight.default,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        ...(window.cardProvider.IS_HSBC_PROVIDER_SETUP
            ? {
                color: '#fff',
            }
            : {}),
    };
}
function getImgSubStyle(theme) {
    return {
        paddingLeft: theme.tsGoUnit.tsUnitDouble,
        textTransform: 'none',
        fontSize: theme.typography.pxToRem(theme.typography.tsFontSize.mini),
        fontWeight: theme.typography.tsFontWeight.default,
        lineHeight: theme.tsGoUnit.tsUnit,
    };
}
function getImgSideSubStyle(theme) {
    return {
        textTransform: 'none',
        fontSize: window.cardProvider.IS_HSBC_PROVIDER_SETUP
            ? theme.typography.tsFontSize.mini
            : theme.typography.pxToRem(theme.typography.tsFontSize.mini),
        fontWeight: theme.typography.tsFontWeight.default,
        lineHeight: theme.tsGoUnit.tsUnit,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        ...(window.cardProvider.IS_HSBC_PROVIDER_SETUP
            ? {
                color: '#fff',
            }
            : {}),
    };
}
function getH3Style(theme) {
    return {
        fontWeight: `${theme.typography.tsFontWeight.bold} !important`,
        fontSize: `${theme.typography.pxToRem(theme.typography.tsFontSize.big)} !important`,
        lineHeight: `${theme.tsGoUnit.tsUnit} !important`,
        marginTop: '25px !important',
        marginBottom: `${theme.tsGoUnit.tsUnitHalf} !important`,
        ...(window.cardProvider.IS_HSBC_PROVIDER_SETUP
            ? {
                fontSize: `${theme.typography.tsFontSize.default} !important`,
                fontWeight: `${theme.typography.tsFontWeight.bolder} !important`,
                color: 'black',
            }
            : {}),
    };
}
function getNavigationDefaultStyle(theme) {
    return {
        fontSize: theme.typography.pxToRem(theme.typography.tsFontSize.default),
        padding: `${theme.tsGoUnit.tsUnitHalf} ${theme.tsGoUnit.tsUnitHalf}`,
        color: theme.palette.tsGoColor.grayDark,
        fontWeight: theme.typography.tsFontWeight.semiBold,
    };
}
function getNavigationActiveStyle(theme) {
    return {
        fontSize: theme.typography.pxToRem(theme.typography.tsFontSize.default),
        padding: `${theme.tsGoUnit.tsUnitHalf} ${theme.tsGoUnit.tsUnitHalf}`,
        fontWeight: theme.typography.tsFontWeight.semiBold,
        borderBottom: `2px solid ${theme.palette.tsGoColor.black}`,
        color: theme.palette.tsGoColor.black,
    };
}
function getTitleStyle(theme) {
    return {
        fontWeight: `${theme.typography.tsFontWeight.semiBold} !important`,
        fontSize: `${theme.typography.pxToRem(theme.typography.tsFontSize.big)} !important`,
        color: theme.palette.tsGoColor.black,
        textAlign: 'center',
    };
}
function getTitleInfoStyle(theme) {
    return {
        fontWeight: `${theme.typography.tsFontWeight.default} !important`,
        fontSize: `${theme.typography.pxToRem(theme.typography.tsFontSize.medium)} !important`,
        color: theme.palette.tsGoColor.black,
        textAlign: 'center',
        marginTop: '20px',
    };
}
function getUserAvatarStyle(theme) {
    return {
        display: 'inline-block',
        height: theme.tsGoUnit.tsUnitDouble,
        width: theme.tsGoUnit.tsUnitDouble,
        borderRadius: theme.tsGoUnit.tsUnit,
        background: window.cardProvider.IS_HSBC_PROVIDER_SETUP
            ? theme.palette.hsbcColor.primary
            : 'linear-gradient(-225deg, hsl(325, 98%, 33%) 27%, #590060 100%)',
        fontSize: window.cardProvider.IS_HSBC_PROVIDER_SETUP
            ? '10px'
            : theme.typography.pxToRem(theme.typography.tsFontSize.big),
        fontWeight: window.cardProvider.IS_HSBC_PROVIDER_SETUP
            ? theme.typography.tsFontWeight.default
            : theme.typography.tsFontWeight.bolder,
        lineHeight: theme.tsGoUnit.tsUnitDouble,
        textAlign: 'center',
        color: 'hsl(0, 0%, 100%)',
    };
}
function getSidemenuSpan(theme) {
    return {
        paddingTop: theme.tsGoUnit.tsUnit,
        marginLeft: '50px',
        transition: 'opacity 0.2s ease-out 0.1s',
        color: theme.palette.tsGoColor.grayLightest,
        fontWeight: theme.typography.tsFontWeight.bold,
        fontSize: theme.typography.tsFontSize.medium,
    };
}
function getSidemenuItemSpan(theme) {
    return {
        paddingLeft: window.cardProvider.IS_HSBC_PROVIDER_SETUP ? theme.tsGoUnit.tsUnitHalf : theme.tsGoUnit.tsUnit,
        display: 'flex',
        alignSelf: 'center',
        fontWeight: theme.typography.tsFontWeight.semiBold,
        fontSize: theme.typography.pxToRem(theme.typography.tsFontSize.medium, tsGoThemeVars_1.defaultMUiFontsizePlusTwo),
        ...(window.cardProvider.IS_HSBC_PROVIDER_SETUP
            ? {
                fontWeight: 500,
                fontSize: theme.typography.tsFontSize.default,
                color: 'white !important',
            }
            : {}),
    };
}
function getMyRequestItemSpan(theme) {
    return {
        fontWeight: theme.typography.tsFontWeight.boldest,
        marginLeft: `${theme.tsGoUnit.tsUnitOneAndHalf} !important`,
        textWrap: 'pretty',
        ...(window.cardProvider.IS_HSBC_PROVIDER_SETUP
            ? {
                color: '#fff',
            }
            : {}),
    };
}
function getMyRequestItemSecondarySpan(theme) {
    return {
        fontWeight: theme.typography.tsFontWeight.default,
        marginLeft: `${theme.tsGoUnit.tsUnitOneAndHalf} !important`,
        fontSize: theme.typography.pxToRem(theme.typography.tsFontSize.mini),
        ...(window.cardProvider.IS_HSBC_PROVIDER_SETUP
            ? {
                color: '#fff',
            }
            : {}),
    };
}
function getSpanStyle(theme) {
    return {
        fontSize: theme.typography.tsFontSize.mini,
        fontWeight: theme.typography.tsFontWeight.semiBold,
        textTransform: 'uppercase',
        ...(window.cardProvider.IS_HSBC_PROVIDER_SETUP
            ? {
                fontWeight: 500,
                fontSize: '12px',
                color: '#000000',
                textTransform: 'none',
            }
            : {}),
    };
}
function getHeaderStyle(theme) {
    return {
        fontSize: theme.typography.tsFontSize.big,
        fontWeight: theme.typography.tsFontWeight.default,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    };
}
function getSideMenuFooterDetailStyle(theme) {
    return {
        display: 'flex',
        flexDirection: 'column',
        color: theme.palette.tsGoColor.white,
        maxWidth: '180px',
    };
}
function getSideMenuHeaderIconStyle(theme) {
    const defaultStyles = {
        alignItems: 'center',
        borderRadius: theme.tsGoUnit.tsUnitOneAndHalf,
        flex: 1,
        height: theme.tsGoUnit.tsUnitOneAndHalf,
        justifyContent: 'center',
        position: 'absolute',
        right: 0,
        top: '15px',
        transition: 'var(--collapseButtonTransition)',
        width: theme.tsGoUnit.tsUnitOneAndHalf,
        zIndex: 1,
        overflow: 'hidden',
    };
    return { ...defaultStyles };
}
function getTeamSwitcherHeaderTitle(theme) {
    return {
        borderBottom: 'hsl(204, 25%, 8%) 1px solid',
        color: 'hsl(0, 0%, 100%)',
        fontSize: '16px',
        fontWeight: theme.typography.tsFontWeight.bold,
        height: theme.tsGoUnit.tsUnit,
        marginTop: '1px',
        padding: theme.tsGoUnit.tsUnit,
    };
}
function getTeamName(theme) {
    return {
        fontWeight: theme.typography.tsFontWeight.default,
        fontSize: theme.typography.tsFontSize.default,
        paddingLeft: theme.tsGoUnit.tsUnit,
        paddingRight: theme.tsGoUnit.tsUnitDouble,
        marginLeft: 0,
        width: '250px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        ...(window.cardProvider.IS_HSBC_PROVIDER_SETUP
            ? {
                color: 'white',
            }
            : {}),
    };
}
function getRoleName(theme) {
    return {
        paddingLeft: theme.tsGoUnit.tsUnit,
        paddingRight: theme.tsGoUnit.tsUnitDouble,
        marginLeft: 0,
        color: theme.palette.tsGoColor.grayDark,
        fontWeight: theme.typography.tsFontWeight.bold,
        fontSize: '11px',
        textTransform: 'uppercase',
    };
}
function getMultiLineStyle() {
    return {
        position: 'absolute',
        left: '100px',
        top: '50%',
        transform: 'translateY(-50%)',
    };
}
function getSecondaryDivStyle(theme) {
    return {
        fontWeight: theme.typography.tsFontWeight.bold,
        fontSize: theme.typography.pxToRem(theme.typography.tsFontSize.small),
        color: theme.palette.tsGoColor.grayDark,
        textTransform: 'uppercase',
    };
}
function getSelectItemStyle(theme) {
    return {
        fontSize: theme.typography.tsFontSize.default,
        fontWeight: theme.typography.tsFontWeight.boldest,
    };
}
function getRoleNameStyle(theme) {
    return {
        width: '100%',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontWeight: theme.typography.tsFontWeight.bold,
        ...(window.cardProvider.IS_HSBC_PROVIDER_SETUP
            ? {
                color: '#ffffff',
            }
            : {}),
    };
}
function getTeamInfoNameStyle() {
    return {
        width: '80%',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    };
}
function getRequestStatusStyle(ownerState, theme) {
    const defaultStyles = {
        fontSize: theme.typography.pxToRem(theme.typography.tsFontSize.default),
        fontWeight: theme.typography.tsFontWeight.bold,
        padding: '10px 0 10px 0',
        textAlign: 'right',
    };
    if (ownerState['ts-variant']?.toLowerCase() === 'approved') {
        return {
            ...defaultStyles,
            color: `${window.cardProvider.IS_HSBC_PROVIDER_SETUP ? theme.palette.hsbcColor.green : theme.palette.tsGoColor.green} !important`,
        };
    }
    else if (ownerState['ts-variant']?.toLowerCase() === 'pending') {
        return {
            ...defaultStyles,
            color: `${theme.palette.tsGoColor.yellow} !important`,
        };
    }
    else if (ownerState['ts-variant']?.toLowerCase() === 'declined') {
        return {
            ...defaultStyles,
            color: `${theme.palette.tsGoColor.red} !important`,
        };
    }
    else {
        return {
            ...defaultStyles,
        };
    }
}
function getIntroHeader(theme) {
    const defaultStyles = {
        color: 'hsl(199, 20%, 22%)',
        fontWeight: '700',
        textAlign: 'center',
    };
    return {
        fontSize: '48px',
        lineHeight: '60px',
        maxWidth: '600px',
        margin: '20px auto 40px',
        ...defaultStyles,
        [theme.breakpoints.down(415)]: {
            fontSize: '24px',
            lineHeight: '30px',
            maxWidth: '80%',
            margin: '20px 10px 40px',
            ...defaultStyles,
        },
    };
}
function getPitchTextStyle() {
    return { fontSize: '18px', marginBottom: '40px' };
}
function getLandingSubHeaderStyle(theme) {
    return {
        color: theme.palette.tsGoColor.slateLighter,
        fontWeight: theme.typography.tsFontWeight.lighter,
        marginBottom: '20px',
        textAlign: 'center',
        width: 'auto',
        [theme.breakpoints.down(415)]: {
            width: '80%',
        },
    };
}
function getHowItWorksInfoStyle(theme) {
    return {
        display: 'inline-block',
        fontWeight: theme.typography.tsFontWeight.lighter,
        margin: '0 auto',
        maxWidth: '370px',
        paddingBottom: '20px',
        [theme.breakpoints.down(415)]: {
            margin: 0,
            width: '80%',
        },
    };
}
function getHowItWorksStepsStyle(theme) {
    return {
        color: theme.palette.tsGoColor.grayDark,
        fontSize: theme.typography.tsFontSize.default,
        fontWeight: theme.typography.tsFontWeight.default,
    };
}
function getTakeControlTextStyle(theme) {
    return {
        margin: '0 auto 40px',
        maxWidth: '580px',
        textAlign: 'center',
        color: theme.palette.tsGoColor.slateLighter,
        fontWeight: theme.typography.tsFontWeight.lighter,
    };
}
function getEmptyWalletTextStyle() {
    return {
        color: 'hsl(198, 20%, 59%)',
        fontSize: '16px',
        textAlign: 'center',
    };
}
exports.muiTypographyOverrides = {
    styleOverrides: {
        root: ({ ownerState, theme }) => ({
            ...(ownerState.variant === 'subtitle1' && getSubtitle1Style(theme)),
            ...(ownerState.variant === 'subtitle2' && getSubtitle2Style(theme)),
            ...(ownerState.variant === 'h6' && getH6Style(theme)), // used for aside title
            ...(ownerState.variant === 'body1' && getBody1Style(theme)), // used as default text
            ...(ownerState.variant === 'body2' && getBody2Style(theme)), // used as small text
            ...(ownerState.variant === 'body2' && ownerState['ts-variant'] === 'active' && getActiveButtonStyle(theme)), // this is the ACTIVE text shown in table cell
            ...(ownerState.variant === 'body2' && ownerState['ts-variant'] === 'inactive' && getInactiveButtonStyle(theme)), // this is the INACTIVE text shown in table cell
            ...(ownerState.variant === 'subtitle2' &&
                ownerState['ts-variant'] === 'tableCellTitle' &&
                getTableCellTitleStyle(theme)), // this is used in Table to show a data cell title text
            ...(ownerState.variant === 'subtitle2' &&
                ownerState['ts-variant'] === 'tableCellTitleName' &&
                getTableCellTitleNameStyle(theme)),
            ...(ownerState.variant === 'subtitle1' &&
                ownerState['ts-variant'] === 'tableCellSubtitle' &&
                getTableCellSubtitleStyle(theme)), // this is used in Table to show a data cell subtitle text
            ...(ownerState.variant === 'subtitle1' &&
                ownerState['ts-variant'] === 'tableCellSubtitleSC' &&
                getTableCellSubtitleStyleSC(theme)),
            ...(ownerState.variant === 'h5' &&
                ownerState['ts-variant'] === 'tableHeaderTitle' &&
                getTableHeaderCellTitleStyle(theme)),
            ...(ownerState.variant === 'h5' && ownerState['ts-variant'] === 'no-list-info' && getNoListStyle()),
            ...(ownerState['ts-variant'] === 'imgSpan' && getImgSpanStyle(theme)),
            ...(ownerState['ts-variant'] === 'imgSideSpan' && getImgSideSpanStyle(theme)),
            ...(ownerState['ts-variant'] === 'imgSub' && getImgSubStyle(theme)),
            ...(ownerState['ts-variant'] === 'navigation-item' && getNavigationDefaultStyle(theme)), // Default style for Navigation panel
            ...(ownerState['ts-variant'] === 'navigation-item active underline' && getNavigationActiveStyle(theme)), // Style used for current active navigation
            ...(ownerState['ts-variant'] === 'title' && getTitleStyle(theme)),
            ...(ownerState['ts-variant'] === 'title-info' && getTitleInfoStyle(theme)),
            ...(ownerState['ts-variant'] === 'imgSideSub' && getImgSideSubStyle(theme)),
            ...(ownerState['ts-variant'] === 'h3' && getH3Style(theme)),
            ...(ownerState['ts-variant'] === 'userAvatar' && getUserAvatarStyle(theme)),
            ...(ownerState['ts-variant'] === 'sideMenuSpan' && getSidemenuSpan(theme)),
            ...(ownerState['ts-variant'] === 'sideMenuItemSpan' && getSidemenuItemSpan(theme)),
            ...(ownerState['ts-variant'] === 'myRequestSpan' && getMyRequestItemSpan(theme)),
            ...(ownerState['ts-variant'] === 'myRequestSecondaySpan' && getMyRequestItemSecondarySpan(theme)),
            ...(ownerState['ts-variant'] === 'span' && getSpanStyle(theme)),
            ...(ownerState['ts-variant'] === 'header' && getHeaderStyle(theme)),
            ...(ownerState['ts-variant'] === 'sideMenmuFooterDetailDiv' && getSideMenuFooterDetailStyle(theme)),
            ...((ownerState['ts-variant'] === 'sidebarHeaderIconDiv' ||
                ownerState['ts-variant'] === 'sidebarHeaderIconCollapsedDiv') &&
                getSideMenuHeaderIconStyle(theme)),
            ...(ownerState['ts-variant'] === 'teamSwitcherHeaderTitle' && getTeamSwitcherHeaderTitle(theme)),
            ...(ownerState['ts-variant'] === 'teamName' && getTeamName(theme)),
            ...(ownerState['ts-variant'] === 'roleName' && getRoleName(theme)),
            ...(ownerState['ts-variant'] === 'multiLine' && getMultiLineStyle()),
            ...(ownerState['ts-variant'] === 'secondary' && getSecondaryDivStyle(theme)),
            ...(ownerState['ts-variant'] === 'select-item' && getSelectItemStyle(theme)),
            ...(ownerState['ts-variant'] === 'roleNameSwitcher' && getRoleNameStyle(theme)),
            ...(ownerState['ts-variant'] === 'intro-header' && getIntroHeader(theme)),
            ...(ownerState['ts-variant'] === 'pitch-text' && getPitchTextStyle()),
            ...(ownerState['ts-variant'] === 'how-it-works-text' && getLandingSubHeaderStyle(theme)),
            ...(ownerState['ts-variant'] === 'how-it-works-info' && getHowItWorksInfoStyle(theme)),
            ...(ownerState['ts-variant'] === 'how-it-works-steps' && getHowItWorksStepsStyle(theme)),
            ...(ownerState['ts-variant'] === 'take-control-text' && getTakeControlTextStyle(theme)),
            ...(ownerState['ts-variant'] === 'speed-up-accounting-text' && getLandingSubHeaderStyle(theme)),
            ...(ownerState['ts-variant'] === 'empty-state' && getEmptyWalletTextStyle()),
            ...(ownerState.variant === 'body1' && ownerState['ts-variant'] === 'teamInfoNameStyle' && getTeamInfoNameStyle()),
            ...(ownerState['ts-request-status'] === 'requestStatus' &&
                ownerState['ts-variant'] &&
                getRequestStatusStyle(ownerState, theme)),
        }),
    },
};
