"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.tsGoUnit = exports.tsGoTypography = exports.goPalette = exports.defaultMUiFontsizePlusTwo = exports.defaultMUiFontsize = void 0;
exports.defaultMUiFontsize = 16;
// we need this is we need to fix the rem size of the MUI font size
// the default size in ts-ui is 14px and in MUI is 16px
exports.defaultMUiFontsizePlusTwo = exports.defaultMUiFontsize + 2;
const tsGoColor = {
    // Base
    white: 'hsl(0, 0%, 100%)',
    black: 'hsl(204, 25%, 8%)',
    // Grey
    gray: 'hsl(198, 20%, 67%)',
    grayLight: (opacity = 1) => `hsla(198, 20%, 83%, ${opacity})`,
    grayDark: 'hsl(198, 20%, 59%)',
    grayDarker: 'hsl(198, 20%, 49%)',
    grayLightest: 'hsl(200, 21%, 92%)',
    // Pink
    pinkLightest: 'hsl(325, 46%, 89%)',
    pinkLighter: 'hsl(325, 48%, 78%)',
    pinkLight: 'hsl(325, 48%, 57%)',
    pink: 'hsl(325, 98%, 33%)',
    pinkDark: 'hsl(327, 99%, 26%)',
    pinkDarker: 'hsl(329, 100%, 21%)',
    // Blue
    blue: 'hsl(199, 100%, 50%)',
    blueDark: 'hsl(201, 100%, 40%)',
    // Green
    greenDark: 'hsl(101, 87%, 33%)',
    greenLight: 'hsl(99, 61%, 63%)',
    greenLighter: 'hsl(99, 60%, 81%)',
    green: 'hsl(99, 85%, 42%)',
    // Red
    red: 'hsl(0, 100%, 37%)',
    lightRed: 'hsl(0, 100%, 37%)',
    // Orange
    orange: 'hsl(33, 100%, 40%)', // original is 50% on lightness
    // Slate
    slateLighter: 'hsl(199, 20%, 31%)',
    slateDarker: 'hsl(197, 20%, 7%)',
    // Yellow
    yellowLightest: 'hsl(44, 95%, 92%)',
    yellow: 'rgb(255, 145, 0)',
    yellowDarker: 'hsl(34, 100%, 49%)',
};
const hsbcColor = {
    primary: 'hsla(355, 100%, 43%, 1)',
    primary_hover: 'hsl(356, 100%, 34%)',
    secondary: 'hsla(0, 0%, 93%, 1)',
    secondary_hover: 'hsl(90, 2%, 84%)',
    // Red
    red: 'rgb(168,0,11)',
    // Green
    green: 'hsla(178, 100%, 26%, 1)',
    // Grey
    gray: 'hsla(0, 0%, 36%, 1)',
    // Yellow
    warning_yellow: 'rgb(255,187,50)',
    // Blue
    blue: 'rgb(48,90,133)',
};
const tsGoBorder = {
    noBorder: 'none',
    dottedBorder: '1px dotted rgba(127,115,72,.5)',
};
exports.goPalette = {
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    // increased  to 2.5 as not all ts-ui colors follow accessibility ratio
    contrastThreshold: 2.5,
    success: {
        main: window.cardProvider.IS_HSBC_PROVIDER_SETUP ? hsbcColor.green : tsGoColor.green,
    },
    error: {
        main: window.cardProvider.IS_HSBC_PROVIDER_SETUP ? hsbcColor.red : tsGoColor.red,
    },
    warning: {
        main: window.cardProvider.IS_HSBC_PROVIDER_SETUP ? hsbcColor.warning_yellow : tsGoColor.orange,
    },
    info: {
        main: window.cardProvider.IS_HSBC_PROVIDER_SETUP ? hsbcColor.blue : tsGoColor.blue,
    },
    tsGoColor,
    tsGoBorder,
    hsbcColor,
    ...(window.cardProvider.IS_HSBC_PROVIDER_SETUP
        ? {
            success: {
                main: hsbcColor.green,
            },
        }
        : {}),
    secondary: {
        dark: 'rgb(230, 236, 239)',
        light: 'rgb(255, 255, 255)',
        main: 'rgb(255, 255, 255)',
    },
};
exports.tsGoTypography = {
    pxToRem(px, baseFontSize = exports.defaultMUiFontsize) {
        return `${px / baseFontSize}rem`;
    },
    ...(window.cardProvider.IS_HSBC_PROVIDER_SETUP
        ? {
            fontFamily: ['"HSBC_MtUnivers_Latin"', 'sans-serif'].join(','),
        }
        : {}),
    tsFontSize: {
        verySmall: 10,
        small: 11,
        mini: 12,
        default: 14,
        medium: 16,
        big: 18,
        xbig: 24,
        icon: 22, // default icon font size
    },
    tsFontWeight: {
        default: 400,
        semiBold: 600,
        bolder: 500,
        bold: 700,
        boldest: 900,
        lighter: 300,
    },
};
const tsUnit = 20;
exports.tsGoUnit = {
    tsUnitQuarter: `${tsUnit / 4}px`,
    tsUnitHalf: `${tsUnit / 2}px`,
    tsUnit: `${tsUnit}px`,
    tsUnitOneAndHalf: `${tsUnit * 1.5}px`,
    tsUnitDouble: `${tsUnit * 2}px`,
    tsUnitTriple: `${tsUnit * 3}px`,
    tsUnitWidthAside: '320px',
};
