"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.signinRoutes = void 0;
const react_1 = require("react");
const reactivateLoader_1 = require("../loaders/signin/reactivateLoader");
const registerActivateLoader_1 = require("../loaders/signin/registerActivateLoader");
const signinPath = '/signin';
const AmexRegistrationForm = (0, react_1.lazy)(() => Promise.resolve().then(() => __importStar(require('../../components/pages/userAccount/registerForm'))));
const HsbcAccountPage = (0, react_1.lazy)(() => Promise.resolve().then(() => __importStar(require('../../components/pages/userAccount/hsbc/accountPage'))));
const AmexActivateForm = (0, react_1.lazy)(() => Promise.resolve().then(() => __importStar(require('../../components/pages/userAccount/activateForm'))));
const ErrorPage = (0, react_1.lazy)(() => Promise.resolve().then(() => __importStar(require('../../components/pages/errorPage'))));
const IS_AMEX_PROVIDER = window.IS_PLAYWRIGHT_RUNNING
    ? window.PLAYWRIGHT_CARD_PROVIDER === 'AMEX'
    : window.cardProvider.IS_AMEX_PROVIDER_SETUP;
exports.signinRoutes = [
    {
        path: `${signinPath}/login`,
        component: (0, react_1.lazy)(() => Promise.resolve().then(() => __importStar(require('../../components/pages/userAccount/loginform')))),
        // loader: passwordLoginLoader,
        // TODO: FIX this if user is logged in and try to access again the login page redirect him to the prev page
    },
    {
        path: `${signinPath}/password/forgot`,
        component: (0, react_1.lazy)(() => Promise.resolve().then(() => __importStar(require('../../components/pages/userAccount/forgotPassword')))),
    },
    {
        path: `${signinPath}/reactivate/:userId/:activationToken`,
        loader: reactivateLoader_1.reactivateLoader,
        component: (0, react_1.lazy)(() => Promise.resolve().then(() => __importStar(require('../../components/pages/userAccount/resetPassword')))),
    },
    {
        path: `${signinPath}/register`,
        component: IS_AMEX_PROVIDER ? AmexRegistrationForm : HsbcAccountPage,
    },
    {
        path: `${signinPath}/message`,
        component: (0, react_1.lazy)(() => Promise.resolve().then(() => __importStar(require('../../components/pages/userAccount/common/messagePage')))),
    },
    {
        // when user goes through an invitation registration
        path: `${signinPath}/activate/:userId/:activationToken`,
        loader: registerActivateLoader_1.registerActivateLoader,
        component: IS_AMEX_PROVIDER ? AmexActivateForm : HsbcAccountPage,
    },
    {
        // when user does the registration by default(no invite)
        path: `${signinPath}/register/activate/:userId/:activationToken`,
        loader: registerActivateLoader_1.registerActivateLoader,
        component: IS_AMEX_PROVIDER
            ? AmexActivateForm
            : !window.config.IS_PRODUCTION || !window.config.IS_SANDBOX
                ? HsbcAccountPage
                : ErrorPage,
    },
    {
        path: `${signinPath}/request-demo`,
        component: (0, react_1.lazy)(() => Promise.resolve().then(() => __importStar(require('../../components/pages/userAccount/requestDemo')))),
    },
];
